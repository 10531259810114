import React from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import coin from 'static/images/single-coin.png';
import bluredCoin from 'static/images/coin-blured.png';
import lightbulbImage from 'static/images/lightbulb.png';
import shadwedCoin from 'static/images/coin-shadowed.png';
import { ReactComponent as CloseIcon } from 'static/icons/close.svg';

import styles from './PromoModal.module.scss';
// import routes from 'config/routes';
import { sample } from 'lodash';
// import { sample } from 'lodash';

const PromoModal: React.FC = () => {
  const { t } = useTranslation();
  const { mainMarketingStore } = useStore();

  return (
    <Modal className={styles.PromoModal} isOpen={mainMarketingStore.isMainMarketingPromoModalOpen}>
      <img className={styles.lightbulb} src={lightbulbImage} alt="" />

      <Typography
        className={styles.title}
        element="h2"
        color="white"
        variant="heading6"
        fontFamily="montserrat"
        responsiveStyles={{ md: 'subtitle1' }}
      >
        {sample(t('modals.promo.affirmations', { returnObjects: true }) as string[])}
        {/* {t('modals.promo.megaBox.title')} */}
      </Typography>
      {/* <Typography
        className={styles.subtitle}
        element="p"
        color="white"
        variant="subtitle1"
        fontFamily="montserrat"
        responsiveStyles={{ md: 'subtitle2' }}
      >
        {t('modals.promo.megaBox.text')}
      </Typography> */}

      {/* <Button.RouterLink
        className={styles.link}
        to={routes.megaBoxMarketing}
        onClick={mainMarketingStore.closeMainMarketingPromoModal}
      >
        {t('modals.promo.link')}
      </Button.RouterLink> */}

      <img className={styles.coin} src={coin} alt="" />
      <img className={styles.coinBlured} src={bluredCoin} alt="" />
      <img className={cx(styles.shadowedCoin, styles.second)} src={shadwedCoin} alt="" />

      <Button className={styles.close} variant="wrapper" onClick={mainMarketingStore.closeMainMarketingPromoModal}>
        <CloseIcon className={styles.icon} />
      </Button>
    </Modal>
  );
};

export default observer(PromoModal);
