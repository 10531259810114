import React from 'react';

import cx from 'classnames';

import { TypographyColorType } from 'types/common';

import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as BoxIcon } from 'static/icons/box.svg';

import styles from './Logo.module.scss';

interface LogoProps {
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  variant?: 'horizontal' | 'vertical' | 'verticalCentered';
  color?: TypographyColorType;
}

const Logo: React.FC<LogoProps> = ({
  className,
  iconClassName,
  textClassName,
  variant = 'horizontal',
  color = 'nightGreen',
}) => {
  return (
    <div className={cx(styles.Logo, styles[variant], className)}>
      <div className={styles.boxIconWrapper}>
        <BoxIcon className={cx(styles.boxIcon, iconClassName)} />
        <svg
          className={styles.hat}
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.1406 23.7188V21.6562C22.9542 21.6562 24.7387 22.1117 26.3304 22.9809L27.5679 21.9471L26.8898 20.4935C25.8202 18.2028 25.2658 15.7054 25.2656 13.1773C25.2656 11.3429 24.5369 9.58369 23.2397 8.28661C20.9052 5.95216 17.739 4.64067 14.4375 4.64062C11.136 4.64067 7.9698 5.95216 5.63527 8.28661L5.15625 8.76562V12.375L16.5 23.7188H21.1406Z"
            fill="#C52C37"
          />
          <path
            d="M27.5669 21.9471L26.8889 20.4935C25.8196 18.2027 25.2656 15.7054 25.2657 13.1773C25.2657 11.3429 24.5369 9.58369 23.2398 8.28661C21.848 6.89649 20.1465 5.85605 18.2748 5.25061C17.7779 5.18888 17.2777 5.15737 16.777 5.15625C16.5273 5.15785 16.2781 5.17801 16.0314 5.21658C11.2691 5.93227 9.37 11.814 12.6282 15.3625C15.1445 18.1077 18.2532 21.1406 21.1407 22.9912V21.6562C22.9542 21.6562 24.7388 22.1117 26.3305 22.9809L27.5669 21.9471Z"
            fill="#ED4241"
          />
          <path
            d="M21.1406 23.7188L20.1094 28.3594C15.6381 27.5885 11.4784 25.5614 8.11604 22.5148C4.75373 19.4682 2.32759 15.5279 1.12097 11.154L1.03125 10.8281L5.15625 8.76562L5.67445 9.75511C9.00042 16.1045 14.4855 21.0567 21.1406 23.7188Z"
            fill="#C1CFE8"
          />
          <path
            d="M21.6563 21.6563H20.625V20.277C20.6257 18.1724 20.2865 16.0814 19.6206 14.0849L20.6003 13.759C21.301 15.8607 21.6576 18.0617 21.6563 20.277V21.6563Z"
            fill="#C52C37"
          />
          <path
            d="M28.875 27.8438C30.5836 27.8438 31.9688 26.4586 31.9688 24.75C31.9688 23.0414 30.5836 21.6562 28.875 21.6562C27.1664 21.6562 25.7812 23.0414 25.7812 24.75C25.7812 26.4586 27.1664 27.8438 28.875 27.8438Z"
            fill="#C1CFE8"
          />
          <path
            d="M5.15621 8.76562L1.13794 10.7766C2.37906 14.2513 4.40732 17.3917 7.06404 19.9522C9.72075 22.5127 12.9339 24.4237 16.452 25.5358C16.8454 25.6593 17.2602 25.6998 17.67 25.6546C18.0799 25.6093 18.4759 25.4794 18.8329 25.2731C19.1899 25.0668 19.5001 24.7885 19.7439 24.456C19.9877 24.1234 20.1597 23.7439 20.2491 23.3413C13.9876 20.6011 8.84685 15.809 5.67441 9.75511L5.15621 8.76562Z"
            fill="#D7E0EF"
          />
          <path
            d="M28.875 21.6563C28.3073 21.6564 27.7507 21.8136 27.2668 22.1105C26.905 22.7022 26.7527 23.3983 26.8346 24.087C26.9164 24.7756 27.2275 25.4167 27.7179 25.9071C28.2083 26.3975 28.8494 26.7086 29.5381 26.7904C30.2267 26.8723 30.9229 26.72 31.5145 26.3582C31.8012 25.8896 31.9577 25.3531 31.968 24.8038C31.9784 24.2546 31.842 23.7125 31.5731 23.2335C31.3042 22.7545 30.9125 22.3558 30.4382 22.0786C29.964 21.8013 29.4244 21.6556 28.875 21.6563Z"
            fill="#D7E0EF"
          />
        </svg>
      </div>
      <Typography className={cx(styles.text, textClassName)} element="span" variant="heading5" color={color}>
        Profit<span>Box</span>
      </Typography>
    </div>
  );
};

export default Logo;
