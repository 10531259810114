import React, { lazy, Suspense } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useStore } from 'store';
import routes from 'config/routes';
import { observer } from 'mobx-react';

import { FeedbackModal } from 'components/modals';
import Snowfall from 'components/surfaces/Snowfall';

import PreloaderPage from 'pages/public/PreloaderPage';
import NotFoundPage from 'pages/public/NotFoundPage';

// Layouts
const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const DashboardLayout = lazy(() => import('layouts/DashboardLayout'));

// Public pages
const LandingPage = lazy(() => import('pages/public/LandingPage'));
const LoginPage = lazy(() => import('pages/public/LoginPage'));
const RegisterPage = lazy(() => import('pages/public/RegisterPage'));
const ResetPasswordPage = lazy(() => import('pages/public/ResetPasswordPage'));

// Private pages
const MainPage = lazy(() => import('pages/private/MainPage'));
const Inviter = lazy(() => import('pages/private/MainPage/components/Inviter'));
const Incomes = lazy(() => import('pages/private/MainPage/components/Incomes'));
const FinancePage = lazy(() => import('pages/private/FinancePage'));
const Transfer = lazy(() => import('pages/private/FinancePage/components/Transfer'));
const History = lazy(() => import('pages/private/FinancePage/components/History'));
const Deposit = lazy(() => import('pages/private/FinancePage/components/Deposit'));
const Withdraw = lazy(() => import('pages/private/FinancePage/components/Withdraw'));
const MarketingPage = lazy(() => import('pages/private/MarketingPage'));
const MainMarketingPage = lazy(() => import('pages/private/MainMarketingPage'));
const BoomboxMarketingPage = lazy(() => import('pages/private/BoomboxMarketingPage'));
const BoomboxStatisticPage = lazy(() => import('pages/private/BoomboxStatisticPage'));
const BoomboxDeskPage = lazy(() => import('pages/private/BoomboxDeskPage'));
const TopboxMarketingPage = lazy(() => import('pages/private/TopboxMarketingPage'));
const TopboxDeskPage = lazy(() => import('pages/private/TopboxDeskPage'));
const TopboxStatisticsPage = lazy(() => import('pages/private/TopboxStatisticsPage'));
const TicTacMarketingPage = lazy(() => import('pages/private/TictacMarketingPage'));
const TicTacDeskPage = lazy(() => import('pages/private/TictacDeskPage'));
const TicTacStatisticsPage = lazy(() => import('pages/private/TictacStatisticsPage'));
const KlondikeMarketingPage = lazy(() => import('pages/private/KlondikeMarketingPage'));
const KlondikeDeskPage = lazy(() => import('pages/private/KlondikeDeskPage'));
const KlondikeStatisticsPage = lazy(() => import('pages/private/KlondikeStatisticsPage'));
const MegaBoxMarketingPage = lazy(() => import('pages/private/MegaBoxMarketingPage'));
const MegaBoxDeskPage = lazy(() => import('pages/private/MegaBoxDeskPage'));
const MegaBoxStatisticsPage = lazy(() => import('pages/private/MegaBoxStatisticsPage'));
const MainMarketingDeskPage = lazy(() => import('pages/private/MainMarketingDeskPage'));
const MainMarketingDeskStatsPage = lazy(() => import('pages/private/MainMarketingDeskStatsPage'));
const StructurePage = lazy(() => import('pages/private/StructurePage'));
const DocumentsPage = lazy(() => import('pages/private/DocumentsPage'));
const SettingsPage = lazy(() => import('pages/private/SettingsPage'));
const NotificationsPage = lazy(() => import('pages/private/NotificationsPage'));
const ReviewsPage = lazy(() => import('pages/private/ReviewsPage'));
const MagicBoxPage = lazy(() => import('pages/private/MagicBoxPage'));
const MagicBoxLotPage = lazy(() => import('pages/private/MagicBoxLotPage'));

// Admin pages
const AdminPage = lazy(() => import('pages/private/AdminPage'));
const AdminUsers = lazy(() => import('pages/private/AdminPage/components/AdminUsers'));
const AdminUser = lazy(() => import('pages/private/AdminPage/components/AdminUser'));
const AdminTransactions = lazy(() => import('pages/private/AdminPage/components/AdminTransactions'));
const AdminTransaction = lazy(() => import('pages/private/AdminPage/components/AdminTransaction'));
const AdminNotifications = lazy(() => import('pages/private/AdminPage/components/AdminNotifications'));
const AdminReviews = lazy(() => import('pages/private/AdminPage/components/AdminReviews'));

const App: React.FC = () => {
  const { authStore } = useStore();

  if (!authStore.userRole) return <PreloaderPage />;

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<PreloaderPage />}>
          <Routes>
            {/* Public Routes */}
            <Route path={routes.root}>
              <Route index element={<LandingPage />} />

              <Route element={<AuthLayout />}>
                <Route path={routes.login} element={<LoginPage />} />
                <Route path={routes.register} element={<RegisterPage />} />
                <Route path={routes.resetPassword} element={<ResetPasswordPage />} />
                <Route path={routes.resetPasswordUpdate} element={<ResetPasswordPage />} />
              </Route>
            </Route>

            {/* Private Routes */}
            <Route path={routes.dashboard} element={<DashboardLayout />}>
              <Route index element={<Navigate to={routes.main} />} />
              <Route path={routes.main} element={<MainPage />}>
                <Route path={routes.myInviter} element={<Inviter />} />
                <Route path={routes.myIncomes} element={<Incomes />} />
              </Route>
              <Route path={routes.finance} element={<FinancePage />}>
                <Route path={routes.transfer} element={<Transfer />} />
                <Route path={routes.history} element={<History />} />
                <Route path={routes.deposit} element={<Deposit />} />
                <Route path={routes.withdraw} element={<Withdraw />} />
              </Route>
              <Route path={routes.marketing} element={<MarketingPage />} />
              <Route path={routes.mainMarketing} element={<MainMarketingPage />} />
              <Route path={routes.boomboxMarketing} element={<BoomboxMarketingPage />} />
              <Route path={routes.boomboxMarketingDeskStats} element={<BoomboxStatisticPage />} />
              <Route path={routes.boomboxMarketingDesk} element={<BoomboxDeskPage />} />
              <Route path={routes.boomboxMarketingDeskPlace} element={<BoomboxDeskPage />} />
              <Route path={routes.mainMarketingDesk} element={<MainMarketingDeskPage />} />
              <Route path={routes.mainMarketingDeskPlace} element={<MainMarketingDeskPage />} />
              <Route path={routes.mainMarketingDeskStats} element={<MainMarketingDeskStatsPage />} />
              <Route path={routes.topboxMarketing} element={<TopboxMarketingPage />} />
              <Route path={routes.topboxMarketingDesk} element={<TopboxDeskPage />} />
              <Route path={routes.topboxMarketingDeskPlace} element={<TopboxDeskPage />} />
              <Route path={routes.topboxMarketingDeskStats} element={<TopboxStatisticsPage />} />
              <Route path={routes.tictacMarketing} element={<TicTacMarketingPage />} />
              <Route path={routes.tictacMarketingDeskStats} element={<TicTacStatisticsPage />} />
              <Route path={routes.tictacMarketingDesk} element={<TicTacDeskPage />} />
              <Route path={routes.tictacMarketingDeskPlace} element={<TicTacDeskPage />} />
              <Route path={routes.klondikeMarketing} element={<KlondikeMarketingPage />} />
              <Route path={routes.klondikeMarketingDesk} element={<KlondikeDeskPage />} />
              <Route path={routes.klondikeMarketingDeskPlace} element={<KlondikeDeskPage />} />
              <Route path={routes.klondikeMarketingDeskStats} element={<KlondikeStatisticsPage />} />
              <Route path={routes.megaBoxMarketing} element={<MegaBoxMarketingPage />} />
              <Route path={routes.megaBoxMarketingDesk} element={<MegaBoxDeskPage />} />
              <Route path={routes.megaBoxMarketingDeskPlace} element={<MegaBoxDeskPage />} />
              <Route path={routes.megaBoxMarketingDeskStats} element={<MegaBoxStatisticsPage />} />
              <Route path={routes.magicBox} element={<MagicBoxPage />} />
              <Route path={routes.magicBoxLot} element={<MagicBoxLotPage />} />
              <Route path={routes.reviews} element={<ReviewsPage />} />
              <Route path={routes.structure} element={<StructurePage />} />
              <Route path={routes.notifications} element={<NotificationsPage />} />
              <Route path={routes.documents} element={<DocumentsPage />} />
              <Route path={routes.settings} element={<SettingsPage />} />

              <Route path={routes.admin} element={<AdminPage />}>
                <Route path={routes.adminUsers} element={<AdminUsers />} />
                <Route path={routes.adminUser} element={<AdminUser />} />
                <Route path={routes.adminTransactions} element={<AdminTransactions />} />
                <Route path={routes.adminTransaction} element={<AdminTransaction />} />
                <Route path={routes.adminNotifications} element={<AdminNotifications />} />
                <Route path={routes.adminReviews} element={<AdminReviews />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <FeedbackModal />
      <Snowfall />
    </>
  );
};

export default observer(App);
